<template>
  <div>
    <v-app-bar color="transparent" flat app absolute nav>
      <slot name="drawer"></slot>
      <v-toolbar-title>Patient Portal</v-toolbar-title>
      <v-spacer></v-spacer>
      <div>
        <v-btn color="secondary" @click="notificationDrawer = true" icon dark>
          <v-badge
            :content="notificationCount"
            :value="notificationCount"
            color="red"
            overlap
          >
            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
        <v-navigation-drawer
          v-model="notificationDrawer"
          :width="$mq === 'mobile' ? '' : '400px'"
          temporary
          :mini-variant="miniVariant"
          hide-overlay
          :expand-on-hover="expandOnHover"
          class="overflow: auto; max-height: 100vh;"
          fixed
          app
          right
        >
          <template v-slot:append>
            <div class="pa-2">
              <v-btn color="error" text block small @click="clearAll()"
                >Delete all Notifications</v-btn
              >
            </div>
          </template>
          <v-list v-if="notifications.length > 0" three-line>
            <v-list-item
              v-for="(item, index) in notifications"
              :key="index"
              three-line
            >
              <v-btn v-if="!item.read" @click="markRead(item)" color="red" icon>
                <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
              </v-btn>
              <v-btn v-else @click="markUnread(item)" color="green" icon>
                <v-icon>mdi-check-circle-outline</v-icon>
              </v-btn>

              <v-list-item-content>
                <v-list-item-title v-html="item.title"></v-list-item-title>
                <v-list-item-subtitle v-html="item.desc"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list v-else min-width="300px">
            <v-list-item>
              <v-list-item-title>No Notifications </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </div>
      <v-avatar @click="goToSettings()" style="cursor: pointer;" class="ml-4">
        <img v-if="!currentUser.photoURL" src="@/assets/avatar.png" />
        <img v-else :src="currentUser.photoURL" />
      </v-avatar>
    </v-app-bar>
  </div>
</template>

<script>
import { db } from '@/firebase'
export default {
  name: 'Appbar',
  props: ['user'],
  data() {
    return {
      miniVariant: false,
      expandOnHover: false,
      notificationDrawer: false,
      right: null,
      show: false,
      notificationCount: 0,
      notifications: [],
      currentUser: {}
    }
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        if (this.user) {
          this.currentUser = this.user
          this.$bind(
            'notifications',
            db
              .collection('Push_Notifications')
              .where('userId', '==', this.currentUser.id)
              .orderBy('date', 'desc')
          )
        }
      }
    },
    notifications: function() {
      this.notificationCount = this.notifications.filter(
        item => !item.read
      ).length
    }
  },

  methods: {
    goToSettings() {
      if (this.currentUser.email !== 'anonymous') {
        if (this.$route.path !== '/my-settings') {
          this.$router.replace('/my-settings')
        }
      } else {
        if (this.$route.path !== '/my-appointments') {
          this.$router.replace('/my-appointments')
        }
      }
    },
    async markRead(item) {
      try {
        await db
          .collection('Push_Notifications')
          .doc(item.id)
          .set({ read: true }, { merge: true })
      } catch (error) {
        this.error = error.message
        console.log(error)
      }
    },
    async markUnread(item) {
      try {
        await db
          .collection('Push_Notifications')
          .doc(item.id)
          .set({ read: false }, { merge: true })
      } catch (error) {
        this.error = error.message
        console.log(error)
      }
    },
    clearAll() {
      for (const item of this.notifications) {
        try {
          db.collection('Push_Notifications')
            .doc(item.id)
            .delete()
        } catch (error) {
          this.error = error.message
          console.log(error)
        }
      }
    }
  }
}
</script>
