<template>
  <v-speed-dial
    v-model="fab"
    :top="top"
    :bottom="bottom"
    :right="right"
    :left="left"
    :direction="direction"
    :open-on-hover="hover"
    :transition="transition"
  >
    <template v-slot:activator>
      <v-btn v-model="fab" color="primary" dark fab>
        <v-icon v-if="fab">
          mdi-cog
        </v-icon>
        <v-icon v-else>
          mdi-cog
        </v-icon>
      </v-btn>
    </template>
    <div id="floatingAction">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            id="btn-1"
            large
            :color="mic ? 'green lighten-3' : 'red lighten-3'"
            >mic</v-icon
          >
        </template>
        <span v-if="mic">Mic Connected</span>
        <span v-else>Mic Not Connected</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            id="btn-3"
            large
            :color="video ? 'green lighten-3' : 'red lighten-3'"
            >videocam</v-icon
          >
        </template>
        <span v-if="video">Camera Connected</span>
        <span v-else>Camera Not Connected</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            id="btn-2"
            large
            :color="audio ? 'green lighten-3' : 'red lighten-3'"
            >volume_up</v-icon
          >
        </template>
        <span v-if="audio">Audio Connected</span>
        <span v-else>Audio Not Connected</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            id="btn-4"
            large
            :color="wifi ? 'green lighten-3' : 'red lighten-3'"
            >wifi</v-icon
          >
        </template>
        <span v-if="wifi">Wifi Connected</span>
        <span v-else>Wifi Not Connected</span>
      </v-tooltip>
    </div>
  </v-speed-dial>
</template>

<script>
export default {
  name: 'FloatingActions',
  data: () => ({
    direction: 'top',
    mic: true,
    video: true,
    fab: false,
    wifi: false,
    audio: true,
    fling: false,
    hover: false,
    tabs: null,
    top: false,
    right: true,
    bottom: true,
    left: false,
    devices: [],
    transition: 'slide-y-reverse-transition'
  }),

  watch: {
    top(val) {
      this.bottom = !val
    },
    right(val) {
      this.left = !val
    },
    bottom(val) {
      this.top = !val
    },
    left(val) {
      this.right = !val
    }
  },
  async mounted() {
    // this.getLocalStream()
    let devicesList = []
    this.devices = []
    await navigator.mediaDevices
      .enumerateDevices()
      .then(devices => {
        devices.forEach(device => {
          devicesList.push(device.kind)
          this.devices.push({ kind: device.kind, label: device.label })
        })
      })
      .catch(function(err) {
        console.log(err.name + ': ' + err.message)
      })

    if (window.navigator.onLine) {
      this.wifi = true
    } else {
      this.wifi = false
    }

    if (devicesList.includes('audioinput')) {
      this.mic = true
    } else {
      this.mic = false
    }

    if (devicesList.includes('audiooutput')) {
      this.audio = true
    } else {
      this.audio = false
    }

    if (devicesList.includes('videoinput')) {
      this.video = true
    } else {
      this.video = false
    }
  },
  methods: {
    async getLocalStream() {
      await navigator.mediaDevices.then(devices => {
        console.log({ devices: devices })
      })
    }
  }
}
</script>

<style scoped>
.v-speed-dial .v-speed-dial__list {
  background: #0082d2 !important;
}
</style>
